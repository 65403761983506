import React from "react"
import _ from "lodash"
import tw, { styled, css } from "twin.macro"
import "styled-components/macro"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import Gallery from "@browniebroke/gatsby-image-gallery"
import Header from "../components/Header"
import { isExternal } from "../utils"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Icon from "../components/Icon"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import InnerHTML from "dangerously-set-html-content"

const Container = styled.div`
  ${tw`px-5 pt-5`}
`
const ContentListContainer = styled.div`
  ${tw`mt-8`}
`
const ScriptDiv = styled(InnerHTML)`
  ${({ redBg }) => (redBg ? tw`prose-invert` : tw`prose`)}
  ${tw`max-w-full 
  `}

  img, iframe {
    ${tw`w-full my-5`}
  }
`
const MarkdownDiv = styled.div`
  ${({ redBg }) => (redBg ? tw`prose-invert` : tw`prose`)}
  ${tw`max-w-full 
  `}

  img, iframe {
    ${tw`w-full my-5`}
  }
`
const PricingTable = styled.table`
  ${tw`
    my-5 table-fixed w-full
  `}
`
const PricingTableHead = styled.thead`
  ${tw`bg-[#EC1F27] text-white border-2 border-[#EC1F27]`}
`
const PricingTableBody = styled.tbody`
  ${tw`border-2`}
`
const PriceTableRow = styled.tr`
  ${tw`
    border-b-2 last:border-b-0
  `}
  & > td {
    ${({ isEven }) => (isEven ? tw`bg-transparent` : tw`bg-black/10`)}
  }
`
const PricingTableHeader = styled.th`
  ${tw`
    text-lg sm:text-xl uppercase 
    p-2.5 w-1/3
  `}
`
const PricingTableCell = styled.td`
  ${tw`
    p-2.5 w-1/3 
    text-base
    sm:text-xl
    // border-x-2 first:border-l-0 last:border-r-0
  `}
`
const PricingTableBtns = styled.div`
  ${tw`flex flex-wrap gap-2 justify-center text-base`}
`
const ListContainer = styled.ul`
  ${tw`mb-8`}
`
const ListItem = styled.li`
  ${tw`flex justify-between items-center text-xl px-4 py-4 border-b border-gray-50`}
  ${({ isEven }) => (isEven ? tw`bg-transparent` : tw`bg-black/10`)}
`
const ImageContainer = styled.div`
  ${tw`max-h-[600px] max-w-[600px] mx-auto overflow-hidden`}
`
const DetailContainer = styled.div`
  ${tw`flex flex-col justify-center`}
`
const Name = styled.div`
  ${tw`font-bold`}
`
const TicketDescription = styled.div`
  ${tw`text-xs opacity-60`}
`
const SubTextContainer = styled.div`
  ${tw`flex gap-2`}
`

const SubText = styled.div`
  ${tw`text-xs opacity-60`}
`
const ValueContainer = styled.div`
  ${tw`font-bold flex`}

  ${({ redBg }) => (redBg ? tw`text-white` : tw`text-[#EC1F27]/90`)}
`
const ButtonExternal = styled.a`
  ${tw`hover:opacity-80 text-white bg-black px-2 py-1 font-bold text-center max-w-[150px] rounded`}
`
const ButtonInternal = styled(Link)`
  ${tw`hover:opacity-80 text-white bg-black px-2 py-1 font-bold text-center max-w-[150px] rounded`}
`
const ButtonExternalPi = styled(ButtonExternal)`
  ${tw`
    flex items-center justify-center gap-1 p-2
    w-full max-w-none sm:w-32 bg-black
  `}
`
const ButtonInternalPi = styled(ButtonInternal)`
  ${tw`
    flex items-center justify-center gap-1 p-2
    w-full max-w-none sm:w-32 bg-black
  `}
`
// the Gallery component from the gatsby-image-gallery package has negative margins
const GalleryContainer = styled.div`
  ${tw`mx-[11px] my-5`}
`
const PatioInfo = styled.div`
  ${tw`my-10`}
`
const PatioInfoHeader = styled.div`
  ${tw`text-white bg-[#EC1F27] p-2.5 font-bold text-2xl`}
`
const PatioInfoUpper = styled.div`
  ${tw`grid md:grid-cols-[2fr,3fr] gap-5 mt-5`}
`
const PatioInfoUpperRight = styled.div`
  ${tw`flex flex-col gap-2.5`}
`
const PatioInfoImage = styled.div`
  ${tw`h-full`}
`
const PatioInfoList = styled.ul`
  ${tw``}
`
const PatioInfoButtons = styled.div`
  ${tw`flex flex-col justify-center md:justify-start sm:flex-row gap-2.5`}
`
const PiButtonContent = styled.div`
  ${tw`min-w-[120px] sm:min-w-0 flex items-center justify-start gap-1.5`}
`
const PatioInfoListItem = styled.li`
  ${tw`flex items-center gap-2.5`}
`
const PatioInfoCards = styled.div`
  ${tw`
    flex flex-wrap justify-center
    mt-5 -mx-[0.3125rem] gap-y-2.5
  `}
`
const PatioInfoCard = styled.div`
  ${tw`
    rounded-lg mx-[0.3125rem] p-2.5
    text-white bg-[#EC1F27] text-center
    w-[calc(50% - 0.625rem)] 
    // xl:w-[calc(33.33% - 0.625rem)]
    flex gap-1.5 items-center justify-evenly
  `}

  ${({ cardNum }) =>
    cardNum &&
    css`
      @media (min-width: 1280px) {
        width: calc((100% / ${cardNum}) - 0.625rem);
      }
    `}
`
const PiCardIconContainer = styled.div`
  ${tw`rounded-full bg-white p-0.5`}

  > .material-icons {
    ${tw`
      text-3xl text-[#EC1F27] 
      block w-9 h-9
    `}
  }
`
const PiCardTextContainer = styled.div`
  ${tw`flex flex-col justify-start text-left`}
`
const PiCardTitle = styled.div`
  ${tw`text-xs uppercase font-semibold`}
`
const PiCardBodyLarge = styled.div`
  ${tw`text-xl font-bold`}
`
const PiCardBodySmall = styled.div`
  ${tw`text-xs font-semibold`}
`

const FlexTable = styled.div`
  ${tw`flex flex-col mb-8`}
`

const FlexTableHeaderRow = styled.div`
  ${tw`flex flex-row font-bold bg-black/20`}
`

const FlexTableRow = styled.div`
  ${tw`flex flex-row`}
  ${({ isEven }) => (isEven ? tw`bg-transparent` : tw`bg-black/10`)}
`

const FlexTableCell = styled.div`
  ${tw`flex-1 p-2.5 text-left`}
`

const InnerPageOne = ({
  data: {
    markdownRemark: {
      frontmatter: { seo, title, hero, tabs, contentList, redBg },
    },
  },
  location,
}) => {
  const breakpoints = useBreakpoint()
  const isMidScreen =
    Object.values(breakpoints).reduce((a, b) => (b ? a + 1 : a), 0) > 1
  const isRedBg = redBg === true
  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
      redBg={isRedBg}
    >
      <Container>
        {title && <Header title={title} heroData={hero} tabsData={tabs} />}
        <ContentListContainer>
          {contentList.map((contentSection, index) => {
            const { type } = contentSection

            if (type === "gallery") {
              const { images } = contentSection

              const imgs = images || []
              const imgCount = imgs.length
              const galleryImgs = imgs.map(img => img.childImageSharp)

              const rem3 = imgCount % 3
              const rem4 = imgCount % 4

              let colDivisor = 3
              if (imgCount >= 4) {
                if (rem4 === 0) {
                  colDivisor = 4
                } else if (rem3 === 0) {
                  colDivisor = 3
                } else if (rem4 > rem3) {
                  colDivisor = 4
                } else {
                  colDivisor = 3
                }
              }
              return (
                <GalleryContainer key={index}>
                  <Gallery
                    images={galleryImgs}
                    colWidth={50}
                    mdColWidth={100 / colDivisor}
                  />
                </GalleryContainer>
              )
            } else if (type === "script") {
              const { htmlScript } = contentSection
              return <ScriptDiv key={index} redBg={isRedBg} html={htmlScript} />
            } else if (type === "markdown") {
              const { markdown } = contentSection
              return (
                <MarkdownDiv
                  key={index}
                  redBg={isRedBg}
                  dangerouslySetInnerHTML={{ __html: markdown }}
                />
              )
            } else if (type === "priceList") {
              const { title, listItem, image } = contentSection

              return (
                <React.Fragment key={index}>
                  <PricingTable>
                    <PricingTableHead>
                      <PriceTableRow>
                        <PricingTableHeader>{title}</PricingTableHeader>
                        <PricingTableHeader>Price</PricingTableHeader>
                        <PricingTableHeader>Buy Options</PricingTableHeader>
                      </PriceTableRow>
                    </PricingTableHead>
                    <PricingTableBody>
                      {listItem.map(({ name, price, buyOptions }, idx) => (
                        <PriceTableRow
                          key={idx}
                          tw="border-dotted"
                          isEven={idx % 2 === 0}
                        >
                          <PricingTableCell>{name}</PricingTableCell>
                          <PricingTableCell tw="text-center">
                            {price}
                          </PricingTableCell>
                          <PricingTableCell>
                            <PricingTableBtns>
                              {isExternal(buyOptions.online) ? (
                                <ButtonExternal
                                  href={buyOptions.online || "#"}
                                  target="_blank"
                                  tw="w-16"
                                >
                                  Online
                                </ButtonExternal>
                              ) : (
                                <ButtonInternal
                                  to={buyOptions.online}
                                  tw="w-16"
                                >
                                  Online
                                </ButtonInternal>
                              )}
                              <ButtonExternal
                                href={`tel:+1${buyOptions.call}`}
                                tw="w-16"
                              >
                                Call
                              </ButtonExternal>
                              <ButtonExternal
                                href={`mailto:${buyOptions.email}`}
                                tw="w-16"
                              >
                                Email
                              </ButtonExternal>
                            </PricingTableBtns>
                          </PricingTableCell>
                        </PriceTableRow>
                      ))}
                    </PricingTableBody>
                  </PricingTable>
                  {!_.isNil(image) && (
                    <ImageContainer>
                      <Gallery
                        images={[image.childImageSharp]}
                        colWidth={100}
                        mdColWidth={100}
                      />
                    </ImageContainer>
                  )}
                </React.Fragment>
              )
            } else if (type === "draftPicksList") {
              const { listItem } = contentSection

              return (
                <ListContainer key={index}>
                  {listItem.map((item, idx) => (
                    <ListItem key={idx} isEven={idx % 2 === 0}>
                      <DetailContainer>
                        <Name>{item.name}</Name>
                        {!isMidScreen && (
                          <TicketDescription>{item.teams}</TicketDescription>
                        )}
                      </DetailContainer>
                      {isMidScreen && (
                        <ValueContainer redBg={isRedBg}>
                          <span>{item.teams}</span>
                        </ValueContainer>
                      )}
                    </ListItem>
                  ))}
                </ListContainer>
              )
            } else if (type === "fiftyFiftyList") {
              const { listItem } = contentSection

              return (
                <FlexTable key={index}>
                  <FlexTableHeaderRow>
                    <FlexTableCell>AGLC #</FlexTableCell>
                    {isMidScreen && (
                      <>
                        <FlexTableCell>Winning 50/50 #</FlexTableCell>
                        <FlexTableCell>Amount</FlexTableCell>
                        <FlexTableCell>Status</FlexTableCell>
                      </>
                    )}
                  </FlexTableHeaderRow>
                  {listItem.map((item, idx) => (
                    <FlexTableRow key={idx} isEven={idx % 2 === 0}>
                      <FlexTableCell>
                        {item.aglc}
                        {!isMidScreen && (
                          <SubTextContainer>
                            <SubText>{item.winningFiftyNumber}</SubText>
                            <SubText>{item.dollarAmount}</SubText>
                            <SubText>{item.claimStatus}</SubText>
                          </SubTextContainer>
                        )}
                      </FlexTableCell>
                      {isMidScreen && (
                        <>
                          <FlexTableCell>
                            {item.winningFiftyNumber}
                          </FlexTableCell>
                          <FlexTableCell>{item.dollarAmount}</FlexTableCell>
                          <FlexTableCell>{item.claimStatus}</FlexTableCell>
                        </>
                      )}
                    </FlexTableRow>
                  ))}
                </FlexTable>
              )
            } else if (type === "patioInfo") {
              const { header, image, infoList, cards, bookingOptions } =
                contentSection

              const { url, icon: onlineIcon } = _.get(
                bookingOptions,
                "online",
                {}
              )
              const { address, icon: emailIcon } = _.get(
                bookingOptions,
                "email",
                {}
              )
              const { number, icon: callIcon } = _.get(
                bookingOptions,
                "call",
                {}
              )

              return (
                <PatioInfo key={index}>
                  <PatioInfoHeader>{header}</PatioInfoHeader>
                  <PatioInfoUpper>
                    <PatioInfoImage>
                      <GatsbyImage
                        image={getImage(image.childImageSharp.full)}
                        style={{ width: "100%", height: "100%" }}
                        objectFit="cover"
                      />
                    </PatioInfoImage>
                    <PatioInfoUpperRight>
                      <PatioInfoList>
                        {infoList.map((info, idx) => (
                          <PatioInfoListItem key={idx}>
                            <div tw="text-[#EC1F27] flex">
                              <Icon name="sports_baseball" />
                            </div>
                            {info}
                          </PatioInfoListItem>
                        ))}
                      </PatioInfoList>
                      {!_.isEmpty(cards) && (
                        <PatioInfoCards>
                          {cards.map(
                            ({ title, icon, body: { large, small } }, idx) => (
                              <PatioInfoCard cardNum={cards.length} key={idx}>
                                <PiCardIconContainer>
                                  <Icon name={icon} />
                                </PiCardIconContainer>
                                <PiCardTextContainer>
                                  <PiCardTitle>{title}</PiCardTitle>
                                  <PiCardBodyLarge>{large}</PiCardBodyLarge>
                                  <PiCardBodySmall>{small}</PiCardBodySmall>
                                </PiCardTextContainer>
                              </PatioInfoCard>
                            )
                          )}
                        </PatioInfoCards>
                      )}
                      <PatioInfoButtons>
                        {isExternal(url) ? (
                          <ButtonExternalPi href={url || "#"} target="_blank">
                            <PiButtonContent>
                              <Icon name={onlineIcon} />
                              Book now
                            </PiButtonContent>
                          </ButtonExternalPi>
                        ) : (
                          <ButtonInternalPi to={url}>
                            <PiButtonContent>
                              <Icon name={onlineIcon} />
                              Book now
                            </PiButtonContent>
                          </ButtonInternalPi>
                        )}
                        <ButtonExternalPi href={`mailto:${address}`}>
                          <PiButtonContent>
                            <Icon name={emailIcon} />
                            Email us
                          </PiButtonContent>
                        </ButtonExternalPi>
                        <ButtonExternalPi href={`tel:+1${number}`}>
                          <PiButtonContent>
                            <Icon name={callIcon} />
                            Call us
                          </PiButtonContent>
                        </ButtonExternalPi>
                      </PatioInfoButtons>
                    </PatioInfoUpperRight>
                  </PatioInfoUpper>
                </PatioInfo>
              )
            }
          })}
        </ContentListContainer>
      </Container>
    </Layout>
  )
}

export default InnerPageOne

export const pageQuery = graphql`
  query SubPageOneQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        redBg
        title
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          pageImagePosition
        }
        tabs {
          label
          url
          icon
        }
        contentList {
          type
          markdown
          htmlScript
          images {
            childImageSharp {
              thumb: gatsbyImageData(
                aspectRatio: 1
                width: 350
                placeholder: BLURRED
                transformOptions: { cropFocus: CENTER }
              )
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          title
          listItem {
            name
            price
            buyOptions {
              online
              email
              call
            }
            teams
            aglc
            winningFiftyNumber
            dollarAmount
            claimStatus
          }
          image {
            childImageSharp {
              thumb: gatsbyImageData(placeholder: BLURRED)
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          header
          infoList
          bookingOptions {
            online {
              url
              icon
            }
            email {
              address
              icon
            }
            call {
              number
              icon
            }
          }
          cards {
            title
            icon
            body {
              large
              small
            }
          }
        }
      }
    }
  }
`
